.filter-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    justify-content: center;
    /* padding: 20px; */
    /* background-color: #fff; */
    /* border-radius: 12px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    /* margin: 20px auto; */
    /* max-width: 700px; */
    margin-left: 30px; 
  }
  
  .location-filter, .car-type-filter, .search-bar {
    flex: 1;
    min-width: 220px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-sizing: border-box;
  }
  
  .search-bar {
    flex-grow: 2;
  }
  
  .filter-options {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .filter-group-radio{
    display: flex;
    flex-direction: row;
  }
  
  .filter-options label {
    font-size: 16px;
    margin-right: 8px;
  }
  
  .filter-options input[type="radio"] {
    margin-right: 5px;
    accent-color: #ff4d4d; /* Modern browser support */
  }
  
  .filter-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 250px !important;
    margin-top: 10px;
  }
  
  .price-range {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .price-range-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .filter-group input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    background: #ddd;
    outline: none;
    opacity: 0.85;
    transition: opacity 0.2s ease-in-out;
  }
  
  .filter-group input[type="range"]:hover {
    opacity: 1;
  }
  
  .filter-group input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    background: #ff4d4d;
    cursor: pointer;
    border-radius: 50%;
  }
  
  .search-button {
    padding: 12px;
    background-color: #2291f3;
    align-items: center;
    text-align: center;
    color: #fff;
    height: 70px;
    width: 140px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 22px;
    font-weight: bold;
    margin-top: 10px;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .search-button:hover {
    background-color: #ff1a1a;
    transform: scale(1.05);
  }
  
  @media (min-width: 768px) {
    .filter-container {
      flex-wrap: nowrap;
      padding: 25px;
    }
  
    .location-filter, .car-type-filter, .search-bar {
      width: auto;
    }
  
    .filter-options {
      justify-content: flex-start;
    }
  
    .filter-group {
      width: 50%; /* Aligns price range with other elements */
    }
    
    .search-button {
      flex-shrink: 0;
    }
  }

  .brand-filter {
    flex: 1;
    min-width: 220px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-sizing: border-box;
  }
  
  .filter-group-container-2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 100px;
  }

  .filter-group-container-1{
    gap: 50px !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .filter-header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    .filter-container{
    display: none;
    }
  }