/* Form container */
.car-form-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    margin-bottom: 990px !important;
  }
  
  /* Form section layout */
  .car-form {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  /* Left and right section */
  .form-section {
    flex: 1;
    min-width: 300px;
  }
  
  .left {
    flex: 1;
  }
  
  .right {
    flex: 1;
  }
  
  /* Form group */
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  /* Image upload section */
  .images-upload {
    border: 2px dashed #ddd;
    padding: 20px;
    text-align: center;
    cursor: pointer;
  }
  
  .image-previews {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .image-preview-container {
    position: relative;
  }
  
  .image-preview {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .delete-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    cursor: pointer;
    border-radius: 50%;
    padding: 5px;
  }
  
  .delete-icon:hover {
    background: rgba(0, 0, 0, 0.7);
  }
  
  /* Feature selection section */
  .feature-selection {
    margin-bottom: 20px;
  }
  
  .feature-icons {
    display: flex;
    gap: 10px;
    /* height:150px;
    width:150px;
    flex-direction: column; */
    flex-wrap: wrap;
  }
  
  .feature-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .feature-icon.selected {
    background-color: #70d85b;
  }

  .feature-icon p {
    margin-top: 5px;
  }
  
  .features-list {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .feature-item {
    display: flex;
    align-items: center;
    margin: 5px;
    padding: 5px;
    border: 1px solid #ddd; /* Optional border for visibility */
    border-radius: 4px; /* Optional rounded corners */
  }
  
  .delete-icon {
    cursor: pointer;
    margin-left: 10px;
    color: #ff0000;
  }
  
  .delete-icon:hover {
    color: #cc0000;
  }
  
  .technical-specifications {
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .technical-specification {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .technical-specification input {
    margin-right: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 150px;
  }
  
  .add-specification-button {
    display: flex;
    align-items: center;
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .add-specification-button:hover {
    background-color: #45a049;
  }
  
  .delete-icon {
    margin-left: 10px;
  }
  
  
  .add-car-button {
    display: block;
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .add-car-button:hover {
    background-color: #0056b3;
  }
  
  .add-car-button:focus {
    outline: none;
  }
  
  .custom-brand-input {
    margin-top: 10px;
  }
  
  .custom-brand-input input {
    margin-right: 10px;
  }
  
  .custom-brand-input button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Reduced opacity background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure it appears above other content */
  }
  
  .loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #ffffff;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .description-textarea {
    width: 100%; /* Adjust as needed */
    height: 150px; /* Adjust as needed */
    resize: vertical; /* Allows users to resize vertically */
  }
  
  
  