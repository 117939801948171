/* import video from '../../../public/video/c2cintro.mp4' */

.home-container {
  /* padding: 20px; */
  background-color: #f8f9fa;
}

.brand-container {
  margin: 50px 0;
  padding: 0 20px;
}

.brand-container h2 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 32px;
  color: #333;
  font-weight: bold;
  position: relative;
}

.brand-container h2::after {
  content: '';
  display: block;
  width: 60px;
  height: 4px;
  background-color: #28a745;
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
}

.slick-slide {
  padding: 0 15px;
}

.car-card {
  background-color: #fff;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
}

.car-card:hover {
  transform: translateY(-12px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.car-image {
  width: 100%;
  height: 240px;
  object-fit: cover;
  border-bottom: 2px solid #e0e0e0;
}
.car-details {
  padding: 20px;
  background-color: #f7f7f7;
  position: relative; /* Ensure the button is positioned relative to the details section */
  padding-bottom: 60px; /* Add extra padding at the bottom to make space for the button */
}

.car-details h3 {
  margin-bottom: 15px;
  font-size: 22px;
  color: #333;
  font-weight: bold;
}

.car-details p {
  margin: 8px 0;
  font-size: 16px;
  color: #666;
}

.buy-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s, transform 0.3s;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.buy-button:hover {
  background-color: #218838;
  transform: translateX(-50%) scale(1.05);
}

/* Custom slider dots and arrows */
.slick-dots {
  bottom: -40px;
}

.slick-dots li button:before {
  font-size: 14px;
  color: #333;
}

.slick-dots li.slick-active button:before {
  color: #28a745;
}

.slick-prev, .slick-next {
  font-size: 24px;
  color: #333;
  z-index: 1;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.slick-prev:hover, .slick-next:hover {
  color: #28a745;
  background-color: #e9ecef;
}


/* Component.css */

.NewUcExCard {
  box-sizing: border-box;
  position: relative;
  background: rgb(255, 255, 255);
  border: 1px solid rgba(36, 39, 44, 0.1);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 10px;
  margin: 0px;
  margin-bottom: 0px;
  font-family: 'Poppins', sans-serif;
  height: 315px;
  list-style-type: none;
  list-style: outside none none;
  text-align: left;
  white-space: nowrap;
}

@media screen and (max-width:778px){
   .NewUcExCard{
    /* display: flex; */
    /* height: auto; */
    width: 200px !important;
   } 
   /* .image_container {
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    height: 154px !important;
    width: 100% !important;
  } */

  
.imagebox {
  box-sizing: border-box;
  cursor: pointer;
  background: rgba(229, 229, 229, 0.3);
  position: relative;
  height: 100%;
  /* width: 140px !important; */
}

.imagebox img {
  box-sizing: border-box;
  border: 0px;
  width: 100% !important;
  border-style: none;
  /* width: 100%; */
  object-fit: cover;
  height: 154px;
}
.car-image{
  height: 30px;
  width: 100px !important;
}

}

.image_container {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  height: 154px;
  font-family: 'Poppins', sans-serif;
}

.imagebox {
  box-sizing: border-box;
  cursor: pointer;
  background: rgba(229, 229, 229, 0.3);
  position: relative;
  height: 100%;
  font-family: 'Poppins', sans-serif;
}

.imagebox img {
  box-sizing: border-box;
  border: 0px;
  max-width: 100%;
  border-style: none;
  width: 100%;
  object-fit: cover;
  height: 154px;
  font-family: 'Poppins', sans-serif;
}

.bottom_container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0px;
  font-family: 'Poppins', sans-serif;
}

.title_heart_section {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  font-family: 'Poppins', sans-serif;
}

.titlebox {
  box-sizing: border-box;
  cursor: pointer;
  width: calc(100% - 35px);
  font-family: 'Poppins', sans-serif;
}

.title {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px 0px 4px;
  overflow: hidden;
  font-weight: 500;
  margin-bottom: 4px;
  color: rgb(36, 39, 44);
  text-overflow: ellipsis;
  width: 98%;
  font-size: 14px;
  line-height: 17px;
  white-space: nowrap;
  font-family: 'Poppins', sans-serif;
}

.titlebox a {
  box-sizing: border-box;
  text-decoration: none;
  background-color: transparent;
  color: rgb(36, 39, 44);
  cursor: pointer;
  font-weight: inherit;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  width: 100%;
  font-family: 'Poppins', sans-serif;
}

.dotsDetails {
  box-sizing: border-box;
  white-space: nowrap;
  font-weight: 400;
  color: rgba(36, 39, 44, 0.7);
  font-size: 10px;
  line-height: 13px;
  font-family: 'Poppins', sans-serif;
}

.dotsDetails span {
  box-sizing: border-box;
  color: rgb(36, 39, 44);
  font-family: 'Poppins', sans-serif;
}

#shortlistHeartIcon {
  box-sizing: border-box;
  border-radius: 50%;
  align-items: center;
  cursor: pointer;
  display: grid;
  justify-content: center;
  background: rgba(0, 0, 0, 0);
  padding: 0px;
  width: 30px;
  height: auto;
  position: relative;
  top: -5px;
  right: -9px;
  font-family: 'Poppins', sans-serif;
}

#shortlistHeartIcon img {
  box-sizing: border-box;
  border: 0px;
  max-width: 100%;
  border-style: none;
  position: relative;
  width: 24px;
  height: auto;
  top: 0px;
  right: 0px;
  font-family: 'Poppins', sans-serif;
}

.Price {
  box-sizing: border-box;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  font-family: 'Poppins', sans-serif;
}

.Price p {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-weight: 600;
  padding-top: 8px;
  color: rgb(36, 39, 44);
  font-size: 18px;
  line-height: 17px;
  font-family: 'Poppins', sans-serif;
}

.discounts {
  box-sizing: border-box;
  margin-top: 0px;
  display: block;
  align-items: center;
  height: 16px;
  font-family: 'Poppins', sans-serif;
}

.sellerdetail {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.lead-btn-holder {
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  width: auto;
  display: inline-block;
  font-family: 'Poppins', sans-serif;
}

.lead-btn-holder button {
  box-sizing: border-box;
  font: inherit;
  margin: 0px;
  overflow: visible;
  text-transform: none;
  appearance: button;
  cursor: pointer;
  padding: 0px;
  background: none;
  border: none;
  align-items: center;
  margin-top: 6px;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  height: 21px;
  line-height: normal;
  color: rgb(247, 93, 52);
  font-family: 'Poppins', sans-serif;
}

.lead-btn-holder button img {
  box-sizing: border-box;
  border: 0px;
  max-width: 100%;
  border-style: none;
  margin-left: 3px;
  css-float: right;
  height: auto;
  width: auto;
  position: relative;
  top: -1px;
  font-family: 'Poppins', sans-serif;
}

.distanceText {
  box-sizing: border-box;
  border-top: 1px dashed rgba(36, 39, 44, 0.15);
  white-space: nowrap;
  overflow: hidden;
  margin-top: 8px;
  padding-top: 8px;
  text-overflow: ellipsis;
  position: absolute;
  bottom: 11px;
  left: 20px;
  width: calc(100% - 40px);
  color: rgb(36, 39, 44);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  font-family: 'Poppins', sans-serif;
}

.distanceText img {
  box-sizing: border-box;
  border: 0px;
  max-width: 100%;
  border-style: none;
  margin: 0px 2px 0px 0px;
  vertical-align: -2px;
  width: 12px;
  height: 12px;
  display: inline;
  font-family: 'Poppins', sans-serif;
}

.gif-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 20px 0;  */
  /* padding: 10px; */
  background-color: #f9f9f9; /* Light background for contrast */
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  overflow: hidden;
  height: 500px; /* Hide overflow if GIF is larger than the container */
}

.styled-gif {
  max-width: 100%;
  height: auto;
  border-radius: 10px; /* Rounded corners */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Add a zoom effect on hover */
.styled-gif:hover {
  transform: scale(1.05); /* Slight zoom on hover */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3); /* More pronounced shadow */
}
/* 
.car-search-form-container {
  background-image: url("/video/car.gif");
} */

.car-search-form-container{
  display: flex;
  justify-content: right;
  align-items: center;
  /* height: 400px; */
  height: 450px;
  /* background-position: center bottom; */
  width: 100%;
  background-size: cover;
  background-color: #f9f9f9 !important;
  /* border-radius: 15px; */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.responsive-iframe{
  width: 100%;
  height: 650px !important; /* Set the desired height */
}

@media screen and (max-width: 768px) {
  .car-search-form-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0b0000 !important;
    height: 180px !important;
    width: 100%;
  }
  .responsive-iframe{
    width: 100%;
    height: 250px !important; /* Set the desired height */
}
}

.car-container-heading {
  font-size: 2.5rem; /* Increase the size for emphasis */
  font-weight: bold; /* Make it bold */
  text-align: start; /* Center align the heading */
  color: #343434; /* Dark grey color for contrast */
  margin-bottom: 1rem; /* Add some space below the heading */
  padding: 0.5rem 0; /* Add some padding for spacing */
  /* background-color: #f5f5f5;  */
  border-radius: 8px; /* Rounded corners */
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
  /* margin-left: 3rem; */
}

.car-container-main {
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* justify-content: space-around; */
  /* align-items: flex-start; */
  gap: 20px;
  /* padding: 20px; */
  background-color: #f8f8f8; 
  border-radius: 10px;
  margin-left: 20px;
}

.cars-brand-container-home {
  display: flex;
  /* margin: 20px 0; */
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-left : 20px;
  margin-right: 20px;
}

/* Mobile-specific Styles */
@media screen and (max-width: 768px) {
  .car-container-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }
  .cars-brand-container-home{
    display: flex;
    flex-direction: column; 
    height: 300px;
    overflow-x: auto;
    scroll-behavior: smooth;
  }
}


/* Common Styles */
.tabs-container {
  display: flex;
  justify-content: space-around;
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 10px;
  margin: 20px 0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.tab {
  padding: 10px 20px;
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.tab:hover {
  background-color: #e2e2e2;
}

.active {
  background-color: #007bff;
  color: #fff;
}

.show-more {
  color: #007bff;
  cursor: pointer;
  font-weight: bold;
}

.show-more:hover {
  text-decoration: underline;
}

/* Desktop-specific Styles */
.desktop {
  display: flex;
}

@media (max-width: 768px) {
  .desktop {
    display: none; /* Hide desktop container on mobile */
  }
}

/* Mobile-specific Styles */
.tabs-container-mobile{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.car-image-mobile{
  height: 50px;
  width: 50px;
}

@media (max-width: 768px) {
  .mobile {
    display: flex; /* Show mobile container on small screens */
  }
  
  .tab {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .brand-logo {
    width: 24px;
    height: 24px;
    /* background: url('/path-to-your-logo.svg') no-repeat center center; */
    background-size: contain;
    margin-right: 10px;
  }
}
.tabs-container-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

@media screen and (min-width: 768px) {
  .tabs-container-mobile{
    display: none;
  }
}

.brands-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 items per row */
  gap: 20px;
}

.brand-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* text-align: center; */
  cursor: pointer;
  /* padding: 10px; */
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.brand-tab.active {
  background-color: #f0f0f0;
}

.brand-logo {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
  text-align: center;
}

.brand-name {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.toggle-button {
  margin-top: 20px;
  font-size: 16px;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.car-rental-container {
  text-align: center;
  font-family: 'Arial', sans-serif;
  padding: 50px;
  width:500px;
  background: rgba(255, 255, 255, 0.05); /* More transparent */
  border-radius: 15px;
  backdrop-filter: blur(10px); /* Adjust the blur effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Border color with transparency */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  /* padding: 20px; Space inside the glass element */
  color: #ffffff; /* Text color */
  position: relative; /* Needed for sliding */
  animation: slideLeft 0.5s forwards; /* Slide animation */
}

@keyframes slideLeft {
  from {
      transform: translateX(100px); /* Start 100px to the right */
      opacity: 0; /* Start transparent */
  }
  to {
      transform: translateX(0); /* End at original position */
      opacity: 1; /* Fully opaque */
  }
}

.car-rental-heading {
  font-size: 33px;
  color: #ffffff;
  margin-bottom: 20px;
}

.car-rental-highlight {
  color: #007bff; /* Blue highlight for "Easily" */
}

.car-rental-description {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 30px;
}

.car-rental-phone-image {
  max-width: 300px;
  margin-bottom: 30px;
}

.car-rental-store-buttons {
  display: flex;
  justify-content: center;
  align-items:center;
  gap: 20px;
}

.car-rental-store-badge {
  width: 150px;
  height: auto;
}

.search-car-button{
  display:flex;
  align-items:center;
  padding:10px;
  gap:10px;
  text-decoration: none;
  border:none;
  border-radius:8px;
  cursor:pointer;
  background-color:light-blue;
  margin-bottom:5px;
  font-weight:bold;
}

@media screen and (max-width: 768px) {
  .search-form-container{
    display: none;
  }
}

.fullscreen-video-container {
  /* position: fixed;
  top: 0;
  left: 0; */
  width: 100vw;
  height: 100%;
  /* z-index: -1; Ensure it's behind other content */
  /* overflow: hidden; */
}

.fullscreen-video {
  /* position: absolute;
  top: 50%;
  left: 50%; */
  width: 100%;
  object-fit: cover;
  height: 100%;
  /* object-fit: cover;  */
  /* transform: translate(-50%, -50%); */
  /* z-index: -1; */
}

.app-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  background-color: #f0f4f8;
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-image {
  max-width: 300px;
  border-radius: 15px;
}

.features-container {
  flex: 1;
  padding-left: 40px;
}

.features-heading {
  font-size: 2rem;
  color: #333;
}

.features-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
}

.feature-item-home {
  display: flex;
  flex-direction: column;
  align-items: start;
  /* background-color: white; */
  padding: 20px;
  border-radius: 10px;
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
}

.icon-home {
  margin-bottom: 10px;
}

.feature-item-home h3 {
  margin: 0;
  font-size: 1.2rem;
  color: #333;
}

.feature-p-home {
  /* align-items:start; */
  /* margin: 10px 0 0 0; */
  color: #666;
  font-size: 0.9rem;
}

@media (max-width: 768px) {

  .app-container {
    flex-direction: column;
    align-items: center;
  }

  .features-heading {
    font-size: 1.6rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .feature-item-home {
    padding: 15px;
  }

  .feature-item-home h3 {
    font-size: 1.1rem;
  }

  .feature-p-home {
    font-size: 0.85rem;
  }
}
.youtube-video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  /* height: 512px; */
  overflow: hidden;
  max-width: 100%;
}

.youtube-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
}




