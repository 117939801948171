/* Bottom Navbar Container */
.bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: linear-gradient(45deg, rgb(0, 123, 255), rgb(48, 188, 244));
    box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-around;
    padding: 15px 0;
    z-index: 9999;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    /* height:/ 0px; */
}

/* Navigation Items */
.nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: 'Poppins', sans-serif;
    transition: color 0.3s ease;
}

.nav-item span {
    font-size: 14px;
    margin-top: 5px;
    font-weight: 500;
    /* text-decoration: none; */
}

/* React Icons styling */
.nav-icon {
    font-size: 20px;
    transition: transform 0.3s ease, color 0.3s ease;
}

/* Hover and Active States */
.nav-item:hover .nav-icon,
.nav-item:hover span {
    color: #fff200; /* Bright yellow for hover */
    transform: scale(1.2);
}

.nav-item.active .nav-icon,
.nav-item.active span {
    color: #fff200; /* Active state in yellow */
    transform: scale(1.2);
}

/* Add spacing to avoid navbar overlapping content */


/* Mobile-first design, always show the nav */
@media (max-width: 768px) {
    .bottom-nav {
        display: flex;
    }
}

/* Hide on larger screens */
@media (min-width: 769px) {
    .bottom-nav {
        display: none;
    }
}


.span-bottom-nav{
    text-decoration:dashed;

}