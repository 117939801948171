.car-details-container {
    padding: 20px;
    width:auto !important;
    display: flex;
    flex-direction: column;
    /* background-color: #f9f9f9; */
  }

  .NewUcExCard{
    width: 300px;
  }

  .car-image-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 30px;
    /* box-shadow: 5px 4px 8px rgba(0, 0, 0, 0.1); */
    height: auto;
  }

  @media screen and (max-width:778px){
    .car-image-container{
      flex-direction: column;
      height:auto;
      align-items: center;
      flex-wrap: wrap; 
      justify-content: center;


    }

    .feature{
       display: flex;
       justify-content: center;
       align-items: center;
       /* gap: 7px; */
        height: 100px;
       /* padding: 7px; */
        width:120px;
    }

    .free-test-drive{
      width: 50px;

    }
    .car-details{
      margin-top: 430px;
    }
  }
  


  .car-img {
    width: 60%;
    height: 400px;
    max-width: 800px;
    /* border-radius: 12px; */
    /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); */
  }
  
  .car-details {
    width: 100%;
    max-width: 1200px;
    padding: 30px;
    /* background-color: #ffffff; */
    border-radius: 12px;
    /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); */
    align-items: start;
    display: flex;
    flex-direction: column;
    justify-content: start !important;
  }
  
  .car-details h1 {
    margin-top: 0;
    font-size: 28px;
    font-weight: 700;
    color: #333;
  }
  
  .car-attributes {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
  }
  
  .attribute {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 48%;
    padding: 15px;
    background-color: #f1f1f1;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .attribute svg {
    font-size: 24px;
    color: #007bff;
  }
  
  .attribute p {
    margin: 0;
    font-size: 18px;
    color: #333;
  }
  
  .car-brand-container {
    margin-top: 30px;
    padding: 20px;
    width: 100%;
    max-width: 1200px;
    background-color: #e9ecef;
    border-radius: 12px;
    border: 1px solid #ccc;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .car-brand-container h2 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    color: #333;
  }
  
  .not-found {
    text-align: center;
    padding: 30px;
    font-size: 20px;
    color: #ff0000;
    font-weight: 600;
  }
  
  .loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: transparent;
    flex-direction: column;
  }
  
  .car-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    /* border-bottom: 2px solid #ddd; */
    margin-bottom: 20px;
    /* background-color: #f8f9fa; */
    padding: 20px;
    border-radius: 12px;
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
    width: 100%;
  }
  
  .car-header-left h1 {
    margin: 0;
    font-size: 28px;
    font-weight: 700;
    color: #2c3e50;
  }
  
  .car-header-left .car-price {
    margin-top: 8px;
    font-size: 20px;
    font-weight: 600;
    color: #e74c3c;
  }
  
  .car-header-right .favourite-icon {
    font-size: 28px;
    color: #ff4757;
    cursor: pointer;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .car-header-right .favourite-icon:hover {
    color: #e74c3c;
    transform: scale(1.2);
  }
  
  .car-header-centre h1 {
    margin: 0;
    font-size: 28px;
    font-weight: 700;
    color: #2c3e50;
  }


  .car-info{
    width: auto;
    height: 60px;
    margin-left: 20px;
    align-items: start;
    display: flex;
    flex-direction: column;
    justify-content: start
  }


  .car-header-centre .car-infos {
    margin-top: 8px;
    font-size: 18px;
    font-weight: 500;
    color: #7f8c8d;
    line-height: 1.6;
    letter-spacing: 0.5px;
  }
  
  .car-header-centre {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .car-header-centre .car-price {
    font-size: 22px;
    font-weight: 700;
    color: #e74c3c;
    margin: 0;
  }
  
  .car-header-centre .car-infos {
    font-size: 18px;
    font-weight: 500;
    color: #2c3e50;
    margin: 0;
    padding-top: 5px;
    border-top: 1px solid #ddd;
  }

  .carousel .thumb{
    width: 200px;
    height: 60px;
  }

  .book-now{
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #8404ae;
    color: #f6f4f6;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .cars-brand-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin: 20px 0;
  }

  .related-cars-heading {
    font-size: 28px;
    font-weight: bold;
    color: #333; /* Adjust this color as per your theme */
    text-align: center;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    position: relative;
    padding-bottom: 10px;
  }

  .btn-container{
    width: 350PX;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    /* margin-top: 20px; */
  }

  .free-test-drive{
    margin-top: 19px;
    margin-right: 30px;
    /* padding: 10px 20px; */
    border-radius: 5px;
    background-color: #2ea9fa;
    color: #050505;
    font-size: 11px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 120px;
    height: 56px;
    align-items: center;
    
  }


  .car-info {
    width: auto;
    padding: 20px;
    margin-left: 20px;
    background-color: #f9f9f9; /* Light background for better contrast */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    display: flex;
    flex-direction: column;
    gap: 15px; /* Space between elements */
}

.car-info h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.car-description {
    font-size: 16px;
    color: #555;
}

.car-info p {
    margin: 5px 0;
    font-size: 14px;
    color: #333;
}

.btn-container {
    display: flex;
    gap: 10px; /* Space between buttons */
    /* margin-top: 20px; */
}

.btn-container button {
    padding: 10px 15px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.book-now {
    background-color: #007bff;
    color: #fff;
}

.book-now:hover {
    background-color: #0056b3;
}

.free-test-drive {
    background-color: #28a745;
    color: #fff;
}

.free-test-drive:hover {
    background-color: #218838;
}

h1 + p {
    margin-top: -5px; /* Adjust margin between price and description */
}

h1 {
    font-size: 28px;
    color: #333;
}

.car-info {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  margin: 0 auto;
}

.car-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.car-description {
  font-size: 16px;
  color: #666;
  margin-bottom: 15px;
}

.car-detail {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
}

.btn-container {
  display: flex;
  justify-content: space-between;
}

.btn-container button {
  width: 48%;
  padding: 10px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.book-now {
  background-color: #007bff;
  color: #fff;
}

.book-now:hover {
  background-color: #0056b3;
}

.free-test-drive {
  background-color: #28a745;
  color: #fff;
}

.free-test-drive:hover {
  background-color: #218838;
}

.share-container {
  margin-top: 20px;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.social-icon {
  font-size: 20px;
  color: #666;
  transition: color 0.3s ease;
  cursor:pointer;
}

.social-icon:hover {
  color: #000;
}

.line {
  border: 0;
  height: 2px; /* Thickness of the line */
  background-color: #333; /* Color of the line */
  margin: 20px 0; /* Space around the line */
  width: 80%; /* Line width */
  align-self: center; /* Center align the line */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Black shadow */
}

.car-details-main{
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
}


@media (max-width: 768px) {
  .car-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 10px;
  }

  .car-header-left {
    flex: 2;
  }

  .car-header-centre {
    display: none; /* Hide the center price info if you want for mobile */
  }

  .car-header-right {
    flex: 1;
    justify-content: flex-end;
  }

  .favourite-icon {
    font-size: 1.2rem;
  }

  .car-header-right p {
    margin-left: 5px;
    font-size: 0.85rem;
  }
}

.feature-heading-text{
  margin-bottom:8px !important;
}

.specifications-card {
  padding: 20px;
  /* background-color: #fff; */
  border-radius: 8px;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}

.card-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.specifications-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.specification-item {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 10px;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); */
}

.spec-icon {
  font-size: 24px;
  margin-right: 10px;
  color: #666;
}

.spec-content {
  display: flex;
  flex-direction: column;
}

.spec-label {
  font-size: 16px;
  color: #888;
}

.spec-value {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

/* Desktop specific styling */
@media (min-width: 768px) {
  .specifications-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Mobile specific styling */
@media (max-width: 767px) {
  .specifications-grid {
    grid-template-columns: 1fr;
  }
  
  .specification-item {
    flex-direction: row;
  }
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.MuiModal-root .MuiPaper-root {
  border-radius: 12px;
  padding: 16px;
  max-width: 90%;
  max-height: 90%;
}
