
/* Header */
.header {
  background-color: #ffffff; /* White background */
  padding: 15px ;
  color: #333333;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
  /* border-bottom: 2px solid #eeeeee; */
  /* position: relative; */
  /* z-index: 1000; Adjust z-index if needed */
}

@media screen and (max-width:778px){
  .header{
  /* padding: 15px 30px; */
  }
  
}

.user-container-hiding{
  display: flex;
}

@media screen and (max-width:778px){
  .user-container-hiding{
    display: none;
  }
  
}

/* Container */
.container-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* max-width: ; */
  /* margin: 0 auto; */
  height: 50px;
}

/* Brand */
.brand {
  display: flex;
  align-items: center;
}

.brand-logo {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  gap:5px;
}

@media screen and (max-width:778px){
  .brand-logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0 !important;
  }
}

.logo-c2c {
  width: 40px;
  height: auto;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
  margin-top: 25px;
  margin-left: 10px;
}

.logo-c2c:hover {
  transform: scale(1.1);
}

.brand-name-c2c {
  margin-top: 0px !important;
  font-size: 28px;
  font-weight: bold;
  text-decoration: none !important;
  color: #333333;
}

/* Navigation */
.nav ul {
  display: flex;
  list-style: none;
}

.nav-item {
  margin-left: 30px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  color: #333333 !important;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  padding-bottom: 5px;
  position: relative;
}

.nav-item::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #333333;
  transition: width 0.3s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.nav-item:hover {
  color: #ff8800;
}

.nav-item:hover::after {
  width: 100%;
}

/* Actions: Search and Icons */
.actions {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
}

/* Icons (Location and Language) */
.icons {
  display: flex;
  align-items: center;

}


@media screen and (max-width:778px){
  .location-search {
    position: relative;
    width: 100%;
}

.location-search-input {
    width: 100%;
    padding: 10px 15px;
    padding-left: 40px; /* Add space for the search icon */
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    outline: none;
    transition: border-color 0.3s ease;
}

.location-search-input:focus {
    border-color: #007bff;
    box-shadow: 0 6px 8px rgba(0, 123, 255, 0.2);
}

.search-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 20px;
    color: #888;
}

.location-search-input::placeholder {
    color: #888;
    font-style: italic;
}

}

.location {
  display: flex;
  align-items: center;
  margin-left: 25px;
  cursor: pointer;
  color: #333333;
  transition: color 0.3s ease, transform 0.3s ease;
  flex-direction: column !important;
  justify-content: center;
  position: relative;
  margin-top: 10px;
  
}

.location-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  /* margin-right: 5px; */
  transition: transform 0.3s ease;
  margin-bottom: 0px !important;
}

.location:hover {
  color: #ff8800;
  transform: scale(1.1);
}

.location-badge {
  background-color: #ff0000;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 4px;
  right: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.location-text {
  /* margin-top: 5px; */
  font-size: 9px;
  color: #333333;
  font-weight: 500;
  text-align: center;
}

.location:hover .location-text {
  color: #ff8800;
}

/* Language Icon */
.language-icon {
  display: flex;
  align-items: center;
  margin-left: 25px;
  cursor: pointer;
  color: #333333;
  transition: color 0.3s ease, transform 0.3s ease;
}

.language-icon:hover {
  color: #ff8800;
  transform: scale(1.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .container-navbar {
    flex-direction: row;
  }
/* 
  .language-icon{
    display: none;
  } */

  .nav ul {
    flex-direction: column;
    
    align-items: center;
    justify-content: start;
    text-align: center;
  }

  .nav-item {
    margin-left: 0;
    margin-top: 10px;
  }

  .search {
    width: 100%;
  }

  .search-input {
    width: 100%;
    visibility: hidden;
  }

  .location, .language-icon {
    margin-left: 15px;
  }
}


.state-search {
  width: 90%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease;
}

.state-search:focus {
  border-color: #007bff;
}

/* States List */
.states-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #555 #1c1c1c;
}

@media screen and (max-width: 768px) {
.state-name-text{
  font-size: 10px;
}

.state-image{
  height: 100px !important;
  width: 100px !important;
}

}

.states-list::-webkit-scrollbar {
  width: 8px;
}

.states-list::-webkit-scrollbar-track {
  background: #1c1c1c;
}

.states-list::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 10px;
}

/* State Item */
.state-item {
  flex: 0 0 calc(33.3333% - 15px); /* Each item takes up 1/3 of the container's width minus the gap */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  max-width: 180px;
  background-color: #2a2a2a;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.state-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

/* State Image with Name Overlay */
.state-image {
  width: 100%;
  height: 120px;
  object-fit: cover;
  transition: filter 0.3s ease;
  cursor: pointer;
}

.state-item:hover .state-image {
  filter: brightness(0.8);
}

.state-name {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 10px 0;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  cursor: pointer;
}

@media screen and (max-width:778px){
  .state-name{
    font-size:15px;
  }
}

/* Show More Button */
.show-more-button {
  background-color: #007bff;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.show-more-button:hover {
  background-color: #0056b3;
}

.nav-item {
  margin: 0 15px;
  text-decoration: none;
  font-size: 18px;
  color: #333;
  position: relative;
}

.nav-item:hover {
  color: #555;
}

.nav-item::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: #333;
  transition: width 0.3s ease-in-out;
}

.nav-item:hover::after {
  width: 100%;
}

.eye-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}




/* Search Input Responsive Width */
@media (max-width: 768px) {
  .search-input {
    width: 100%; /* Full width on mobile */
  }
  .nav-item{
    display: none;
  }
/* 
  .search-input{
    display: none;
  } */

  .location-icon{
    display: flex;
  }

  .location-text{
    display: flex;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .search-input {
    width: 300px; /* Medium width on tablet */
  }
}


.eye-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}




/* 
.container-navbar {
  display: flex;
  flex-direction: column;
  height: 100vh;
} */

.content {
  flex: 1;
  overflow-y: auto;
}

.footer {
  flex-shrink: 0;
}
/* Base container */
.search-container {
  position: relative;
  /* padding: 8px; */
  background-color: #fff;
  transition: background-color 0.3s ease;
  /* border-bottom: 1px solid #ccc; */
}

.search-header {
  display: flex;
  align-items: center;
  /* padding: 10px 0; */
}

.search-icon, .back-icon {
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.search-input {
  border: none;
  outline: none;
  padding: 10px;
  font-size: 16px;
  width: 0;
  opacity: 0;
  visibility: hidden;
  transition: width 0.3s ease, opacity 0.3s ease;
}

/* For mobile: toggle search bar */
.search-input.visible {
  width: 100% !important;
  opacity: 1;
  visibility: visible;
}

/* Mobile-specific behavior */
@media (max-width: 768px) {
  .search-container.open {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #f8f9fa;
    padding: 16px;
    border-bottom: 1px solid #ccc;
    z-index: 1000;
  }

  .search-input.visible {
    width: 100%;
  }

  .trending-cars {
    margin-top: 60px;
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .trending-cars h3 {
    margin-bottom: 10px;
    font-size: 18px;
    color: #333;
  }

}

/* Desktop behavior */
@media (min-width: 769px) {
  .search-container {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    position: relative;
   
  }

  /* Make search input always visible for desktop */
  .search-input {
    width: 400px !important;
    opacity: 1;
    visibility: visible;
    border: 1px solid #ccc;
    border-radius: 8px;
    /* padding: 10px; */
    transition: none;
  }

  /* Hide the search icon for desktop since search is always visible */
  .search-icon, .back-icon {
    display: none;
  }

  .trending-cars {
    margin-top: 16px;
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  }

  .trending-cars h3 {
    margin-bottom: 10px;
    font-size: 18px;
    color: #333;
  }

  .trending-cars ul {
    list-style: none;
    padding: 0;
  }

  .trending-cars li {
    display: flex;
    align-items: center;
    padding: 8px 0;
    font-size: 16px;
    color: #555;
  }

  .trending-cars li svg {
    margin-right: 10px;
    font-size: 20px;
  }
}


/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal container */
.modal-login {
  background: #fff;
  width: 400px;
  max-width: 90%;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  animation: slideDown 0.3s ease-in-out;
}

/* Close button */
.modal-close-button {
  background: transparent;
  border: none;
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

/* Modal content */
.modal-content {
  text-align: center;
}

.modal-content h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
}

/* Input fields */
.modal-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.password-container {
  position: relative;
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
  color: #888;
}

/* Buttons */
.modal-button {
  width: 100%;
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.modal-button:hover {
  background-color: #45a049;
}

/* Resend OTP and error messages */
.modal-toggle {
  color: #007bff;
  cursor: pointer;
}

/* .modal-toggle:hover {
  text-decoration: underline;
} */

.error-message {
  color: red;
  font-size: 14px;
  margin: 10px 0;
}

.success-message {
  color: green;
  font-size: 14px;
  margin: 10px 0;
}

/* Utility: Animation */
@keyframes slideDown {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}


/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

/* Modal container */
.modal-register {
  background: white;
  padding: 20px;
  width: 400px;
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  margin: 50px auto;
  z-index: 1001;
}

/* Modal content */
.modal-content {
  text-align: center;
}

/* Modal header */
.modal-content h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

/* Input fields */
.modal-input {
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  transition: all 0.3s ease;
}

.modal-input:focus {
  border-color: #007bff;
}

/* Button styles */
.modal-button,
.modal-button-verify {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.modal-button:hover,
.modal-button-verify:hover {
  background-color: #0056b3;
}

/* Success message */
.success-message {
  color: #28a745;
  font-size: 14px;
  margin-bottom: 10px;
}

/* Eye icon for password visibility toggle */
.eye-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
}

/* Password container */
.password-container {
  position: relative;
  margin-bottom: 15px;
}

/* Modal close button */
.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* Toggle links */
.modal-toggle {
  color: #007bff;
  cursor: pointer;
  /* text-decoration: underline; */
}

.modal-toggle:hover {
  color: #0056b3;
}

/* OTP container */
.otp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.otp-container input {
  width: calc(100% - 100px);
  margin-bottom: 10px;
}

/* Resend OTP container */
.resend-otp-container {
  margin-top: 10px;
  text-align: right;
}

.user-text {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
}

.logout-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff; /* Blue background */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.logout-button:hover {
  background-color: #d4875d; /* Darker blue for hover effect */
}

.logout-button:active {
  background-color: #004080; /* Even darker blue for active state */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.logout-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Blue outline for focus */
}

.navbar-heading{
  font-size: 15px;
  font-weight: bold;
  color: #333;
}

@media screen and (max-width: 768px) {
  .navbar-heading{
    font-size: 10px;
  }
}

/* Dropdown container */
.dropdown {
  /* margin-top: 250px !important; */
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  max-height: 250px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  width: 400px;
}

/* Dropdown item */
.dropdown li {
  padding: 15px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-bottom: 1px solid #e0e0e0;
}

/* Hover effect for dropdown item */
.dropdown li:hover {
  background-color: #f2f6f9;
}

/* Styling for the last dropdown item (no border) */
.dropdown li:last-child {
  border-bottom: none;
}

/* Active selection style */
.dropdown li.active {
  background-color: #3498db;
  color: white;
}

/* When the dropdown is empty */
.dropdown.empty {
  text-align: center;
  padding: 15px;
  font-size: 14px;
  color: #999;
}

/* Scrollbar styling for better UX */
.dropdown::-webkit-scrollbar {
  width: 8px;
}

.dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

.dropdown::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
}

.dropdown::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.car-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd; /* Light border */
  border-radius: 5px;     /* Rounded corners */
  margin: 5px 0;          /* Space between items */
  cursor: pointer;         /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth background transition */
}

.car-item:hover {
  background-color: #f0f0f0; /* Light gray background on hover */
}

.car-item-icon {
  margin-right: 10px; /* Space between icon and text */
  color: #007bff;     /* Icon color */
  font-size: 24px;    /* Icon size */
}

.car-item-text {
  font-size: 16px;    /* Text size */
  color: #333;        /* Text color */
}

/* Mobile styles */
@media (max-width: 768px) {
  .car-item {
    padding: 15px;         /* Increased padding for touch targets */
    /* flex-direction: column; Stack items vertically */
    align-items: flex-start; /* Align items to the start */
  }

  .car-item-icon {
    margin-bottom: 5px;    /* Space below icon */
    font-size: 28px;       /* Slightly larger icon on mobile */
  }

  .car-item-text {
    font-size: 18px;       /* Larger text for readability */
  }
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000; /* Ensure the overlay is on top */
}

.modal {
  background-color: #fff; /* White background for modal */
  border-radius: 8px;     /* Rounded corners */
  padding: 20px;          /* Padding inside modal */
  max-width: 600px;       /* Max width for the modal */
  width: 90%;              /* Responsive width */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Soft shadow */
  position: relative;      /* Position relative for close button */
}

.location-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  height: 500px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1; 
}

@media screen and (max-width: 768px) {
  .location-container {
    height: 400px;
  }
}

.location-container::-webkit-scrollbar {
  width: 8px;
}

.location-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.location-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
  border: 2px solid transparent;
}

.location-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.location-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.close-btn {
  align-self: flex-start;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.location-title {
  font-size: 20px;
  margin-bottom: 10px;
}



.location-content {
  border-top: 1px solid #ddd;
  margin-top: 10px;
}

.location-category {
  font-size: 16px;
  color: #007aff;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.location-item {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  cursor: pointer;
}

.location-item:hover {
  background-color: #f5f5f5;
}

.location-arrow {
  font-size: 18px;
  color: #888;
}

.google-login-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}
.link-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  gap:5px;
}

.button-custom-link {
  text-decoration: none;
  font-size: 16px;
  padding: 10px 0;
  border-bottom: 2px solid transparent;
  color: #666; /* Default text color */
  transition: color 0.3s ease, border-bottom 0.3s ease; /* Smooth transitions */
}

.button-custom-link.activee {
  border-bottom: 2px solid #007bff; /* Same color for both text and border */
  color: #007bff; /* Active state color */
  font-weight: bold;
}

.button-custom-link:hover {
  border-bottom: 2px solid #007bff;
  color: #007bff; /* Hover color same as active */
}

.link-container a + a {
  margin-left: 0; /* No space between links */
}

.select-item {
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  /* appearance: none; Remove default arrow in some browsers */
  /* -webkit-appearance: none; For Safari */
  /* -moz-appearance: none; For Firefox */
  position: relative;
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.select-item:hover {
  border-color: #007bff;
}

.select-item:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Highlight on focus */
}

.select-item::after {
  content: '\25BC'; /* Unicode for dropdown arrow */
  position: absolute;
  right: 15px;
  pointer-events: none;
}

.select-item option {
  padding: 10px;
}

.blue-button {
  background-color: #007bff;
}

@media  screen and (min-width: 768px) {
  .location-search{
    display: none;
  }
}